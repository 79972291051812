<template>
  <div class="page flex-col">
    <div class="group_1 flex-row" >
      <div class="flex-row group_1_left" style="">
        <div class="img-left"><img style="width: 70px;height: 70px" :src='require("@/img/BuyerIndex/ic_head_2.png")' /></div>
        <div class="text-right flex-col">
          <span class="text-right-one">{{ user.name }}</span>
          <div class="text-right-two-div"><span class="text-right-two">企业账户</span></div>
        </div>
      </div>
      <div class="flex-col group_1_center" style="">

        <div class="flex-row group_1_center_onerow">
          <div class="left_name">所属企业：{{ company.name }}</div>
          <div class="right_name">联系方式：{{ user.phone | filterTel }}</div>
        </div>

        <div class="flex-row group_1_center_tworow">
          <div class="left_name">
              企业认证：<span v-if="company.isAuthentication === 1">已认证为企业账号</span>
            <span>未认证</span>

          </div>
          <div class="right_name">企业邮箱：{{ company.email }}</div>
        </div>

        <div class="flex-row group_1_center_threerow">
          <div class="left_name">我的关注：</div>
            <div class="center_name">商品 <span style="color: #F75145;">{{ focusNum }}</span> 件</div>
<!--            <div class="right_name">服务商 <span style="color: #F75145">17</span>家</div>-->
        </div>

      </div>
      <div class="flex-col group_1_right" style="">
        <div class="group_1_but_one flex-row cursor-item" @click="goAddressDisplay()"><span class="one_span">地址管理</span></div>
        <div class="group_1_but_two flex-row cursor-item" @click="goAccount()"><span class="two_span">账户信息</span></div>
      </div>
    </div>
    <div class="group_2 flex-row" v-if="indexDisplay">
      <div class="flex-col">
        <div class="flex-col group_2_center" >
            <div class="flex-row group_2_center_top">
              <div class="group_2_center_left"><span class="group_2_center_left_span">我的订单</span></div>
              <div class="group_2_center_right"><div class="group_2_center_right_but" @click="showAll"><span class="group_2_center_right_but_span">查看全部</span></div></div>
            </div>
            <div class="flex-row group_2_center_buttom">

              <div class="flex-col group_2_center_one">
                <div class="group_2_center_one_title"><span class="group_2_center_one_title_span">未付款</span></div>
                <div class="group_2_center_one_text"><span class="group_2_center_one_text_span">{{ order.noPay }}</span></div>
              </div>

              <div class="flex-col group_2_center_two">
                <div class="group_2_center_two_title"><span class="group_2_center_two_title_span">未发货</span></div>
                <div class="group_2_center_two_text"><span class="group_2_center_two_text_span">{{ order.noSend }}</span></div>
              </div>

              <div class="flex-col group_2_center_three">
                <div class="group_2_center_three_title"><span class="group_2_center_three_title_span">已发货</span></div>
                <div class="group_2_center_three_text"><span class="group_2_center_three_text_span">{{ order.send }}</span></div>
              </div>

              <div class="flex-col group_2_center_four">
                <div class="group_2_center_four_title"><span class="group_2_center_four_title_span">已完成</span></div>
                <div class="group_2_center_four_text"><span class="group_2_center_four_text_span">{{ order.complete }}</span></div>
              </div>

              <div class="flex-col group_2_center_five">
                <div class="group_2_center_five_title"><span class="group_2_center_five_title_span">退款/退货</span></div>
                <div class="group_2_center_five_text"><span class="group_2_center_five_text_span">{{ order.returnFor }}</span></div>
              </div>

            </div>
        </div>
<!--      <div class="flex-col group_2_right" >
          <div class="flex-row group_2_right_top "><span class="group_2_right_top_span">系统公告</span></div>
          <div class="flex-col group_2_right_but product_scroll">
            <div class="flex-col group_2_right_but_one" v-for="(item,idx) in noticeList" :key="idx">
                <span class="group_2_right_but_one_span single_over_hidden cursor-item">「公告」{{item.title}}</span>
            </div>
          </div>
      </div>-->
        <div class="flex-col group_3_center" style="">

          <div class="flex-row group_3_center_top">
            <div class="group_3_center_left"><span class="group_3_center_left_span">待办信息</span></div>
            <div class="group_3_center_right"><div class="group_3_center_right_but" @click="showAll"><span class="group_3_center_right_but_span">查看全部</span></div></div>
          </div>
          <div class="flex-row group_3_center_buttom">
            <div class="flex-col group_3_center_one">
              <div class="group_3_center_one_title"><span class="group_3_center_one_title_span_1">{{billingNum}}</span><span class="group_3_center_one_title_span">条待确认</span></div>
              <div class="group_3_center_one_text"><span class="group_3_center_one_text_span">待支付订单</span></div>
            </div>

            <div class="flex-col group_3_center_two">
              <div class="group_3_center_two_title"><span class="group_3_center_two_title_span_1">{{workOrder}}</span><span class="group_3_center_two_title_span">条待审核</span></div>
              <div class="group_3_center_two_text"><span class="group_3_center_two_text_span">待办工单</span></div>
            </div>

            <div class="flex-col group_3_center_three">
              <div class="group_3_center_three_title"><span class="group_3_center_three_title_span_1">{{ renewalNum }}</span><span class="group_3_center_three_title_span">条待开票</span></div>
              <div class="group_3_center_three_text"><span class="group_3_center_three_text_span">待续费资源</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col">
        <div class="flex-col group_2_right" >
          <div class="flex-row group_2_right_top "><span class="group_2_right_top_span">系统公告</span></div>
          <div class="flex-col group_2_right_but product_scroll">
            <div class="flex-col group_2_right_but_one" v-for="(item,idx) in noticeList" :key="idx">
              <span class="group_2_right_but_one_span single_over_hidden cursor-item">「公告」{{item.title}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="group_3 flex-row" v-if="indexDisplay">-->
<!--      <div class="flex-col group_3_center" style="">

        <div class="flex-row group_3_center_top">
          <div class="group_3_center_left"><span class="group_3_center_left_span">待办信息</span></div>
          <div class="group_3_center_right"><div class="group_3_center_right_but" @click="showAll"><span class="group_3_center_right_but_span">查看全部</span></div></div>
        </div>
        <div class="flex-row group_3_center_buttom">

          <div class="flex-col group_3_center_one">
            <div class="group_3_center_one_title"><span class="group_3_center_one_title_span_1">{{billingNum}}</span><span class="group_3_center_one_title_span">条待确认</span></div>
            <div class="group_3_center_one_text"><span class="group_3_center_one_text_span">待支付订单</span></div>
          </div>

          <div class="flex-col group_3_center_two">
            <div class="group_3_center_two_title"><span class="group_3_center_two_title_span_1">{{workOrder}}</span><span class="group_3_center_two_title_span">条待审核</span></div>
            <div class="group_3_center_two_text"><span class="group_3_center_two_text_span">待办工单</span></div>
          </div>

          <div class="flex-col group_3_center_three">
            <div class="group_3_center_three_title"><span class="group_3_center_three_title_span_1">{{ renewalNum }}</span><span class="group_3_center_three_title_span">条待开票</span></div>
            <div class="group_3_center_three_text"><span class="group_3_center_three_text_span">待续费资源</span></div>
          </div>

        </div>

      </div>-->
<!--      <div class="flex-col group_3_right" style="">
        <div class="flex-row group_2_right_top"><span class="group_2_right_top_span">产品通知</span></div>
        <div class="flex-col group_2_right_but product_scroll">
          <div class="flex-col group_2_right_but_one"><span class="group_2_right_but_one_span single_over_hidden">「公告」云市场旧商品发布模版下线123451234...</span></div>
          <div class="flex-col group_2_right_but_two"><span class="group_2_right_but_two_span single_over_hidden">「公告」云市场旧商品发布模版下线123451234...</span></div>
          <div class="flex-col group_2_right_but_three"><span class="group_2_right_but_three_span single_over_hidden">「公告」云市场旧商品发布模版下线123451234...</span></div>
          <div class="flex-col group_2_right_but_four"><span class="group_2_right_but_four_span single_over_hidden">「公告」云市场旧商品发布模版下线123451234...</span></div>
        </div>
      </div>-->
<!--    </div>-->

    <div class="group_account flex-row" v-if="accountDisplay">
      <div class="flex-col group_account_center" style="">

        <div class="flex-row group_account_center_top">
          <div class="group_account_center_left"><span class="group_account_center_left_span">账户信息</span></div>
          <div class="group_account_center_right"><div class="group_account_center_right_but cursor-item" @click="editAccountMe"><span class="group_account_center_right_but_span">编辑信息</span></div></div>
        </div>

        <div class=" group_account_center_buttom">

          <el-row>
            <el-col :span="3">
              <img style="width: 4.5vw;height: 4.5vw" :src='require("@/img/BuyerIndex/ic_head_2.png")' />
            </el-col>
            <el-col :span="21" v-if="!editAccount">
                <el-row style="margin-top: 0vw">
                  <span class="buyer_font_1vw">账户名：   {{ user.name }}</span>
                </el-row>
                <el-row style="margin-top: 1vw">
                  <span class="buyer_font_1vw">联系邮箱：{{ user.email }}</span>
                </el-row>
                <el-row style="margin-top: 1vw">
                  <span class="buyer_font_1vw">联系电话：{{ user.phone | filterTel}}</span>
                </el-row>
                <el-row style="margin-top: 1vw">
                  <span class="buyer_font_1vw">所属企业：{{ company.name }}</span>
                </el-row>
              <el-row style="margin-top: 1vw">
                <span class="buyer_font_1vw">联络地址：{{ user.userAddress }}</span>
              </el-row>
<!--              <el-row style="margin-top: 1vw">-->
<!--                <span class="buyer_font_1vw">公司简介：深圳伊登软件有限公司成立于2003年，是国内综合IT解决方案服务和云服务提供商，拥有自己的团队和产品</span>-->
<!--              </el-row>-->
              <el-row style="margin-top: 2vw">
                <el-button type="danger" plain style="padding: 0.5vw 1vw 0.5vw 1vw; margin-bottom: 20px" @click="logOut">退出登录</el-button>
<!--                <el-button type="danger" plain style="padding: 0.5vw 1vw 0.5vw 1vw;">切换账号</el-button>-->
              </el-row>
            </el-col>

            <el-col :span="21" v-if="editAccount">
              <el-row style="margin-top: 0vw">
                <el-col :span="2"> <span class="buyer_font_1vw">账户名：   </span></el-col>
                <el-col :span="20"><el-input v-model="userForm.name" placeholder="请输入内容"></el-input></el-col>
              </el-row>
              <el-row style="margin-top: 1vw">
                <el-col :span="2"><span class="buyer_font_1vw">联系邮箱：</span></el-col>
                <el-col :span="20"><el-input v-model="userForm.email" placeholder="请输入内容"></el-input></el-col>
              </el-row>
              <el-row style="margin-top: 1vw">
                <el-col :span="2"><span class="buyer_font_1vw">联系电话：</span></el-col>
                <el-col :span="20"><el-input v-model="userForm.mobile" placeholder="请输入内容"></el-input></el-col>
              </el-row>
              <el-row style="margin-top: 1vw">
                <el-col :span="2"><span class="buyer_font_1vw">所属企业：</span></el-col>
                <el-col :span="20"><el-input v-model="userForm.company" placeholder="请输入内容" disabled></el-input></el-col>
              </el-row>
              <el-row style="margin-top: 1vw">
                <el-col :span="2"><span class="buyer_font_1vw">联络地址：</span></el-col>
                <el-col :span="20"><el-input v-model="userForm.address" placeholder="请输入内容"></el-input></el-col>
              </el-row>
<!--              <el-row style="margin-top: 1vw">-->
<!--                <el-col :span="2"><span class="buyer_font_1vw">公司简介：</span></el-col>-->
<!--                <el-col :span="20"><el-input v-model="input" placeholder="请输入内容"></el-input></el-col>-->
<!--              </el-row>-->
              <el-row style="margin-top: 2vw;margin-bottom: 30px">
                <el-button type="danger" plain style="padding: 0.5vw 1vw 0.5vw 1vw;" @click="saveUserInfo">保存</el-button>
                <el-button type="danger" plain style="padding: 0.5vw 1vw 0.5vw 1vw;" @click="closeEdit">取消编辑</el-button>
              </el-row>
            </el-col>
          </el-row>
        </div>



      </div>

    </div>

    <div class="group_account flex-row" v-if="addressDisplay">
      <div class="flex-col group_account_center" style="">

        <div class="flex-row group_account_center_top">
          <div class="group_account_center_left"><span class="group_account_center_left_span">地址管理</span></div>
          <div class="group_account_center_right"><div class="group_account_center_right_but" @click="addAddressMe"><span class="group_account_center_right_but_span">新增地址</span></div></div>
        </div>

        <div class=" group_address_center_buttom"  v-if="!addAddress">

          <el-row>
            <el-col :span="24" v-if="true">

              <el-row class="" style="margin-right: 3vw;margin-top: 1vw;padding: 0vw 0vw 1vw 0vw;" :class="{ 'border_line':idx !== addressList.length - 1 }" v-for="(item,idx) in addressList" :key="idx">

                <el-col :span="4" class="" style="margin: 1vw 0vw 0vw 0vw">
                  <span class="buyer_font_1vw" >{{item.isDefault === 1 ? '默认地址' : '非默认地址'}}</span>
                </el-col>

                <el-col :span="4" class="">
                  <el-row style="margin: 0vw 0vw 1vw 0vw"><span class="buyer_font_1vw" >收件人：{{item.addresseeName}}</span></el-row>
                  <el-row><span class="buyer_font_1vw">邮政编码：{{item.postalCode}}</span></el-row>
                </el-col>

                <el-col :span="10" class="">
                  <el-row style="margin:0vw 0vw 1vw 0vw">
                    <span class="buyer_font_1vw" > 联系电话：{{item.phone}}</span>
                    <span class="buyer_font_1vw" style="margin: 0vw 0vw 0vw 6vw">标签：公司</span>
                  </el-row>
                  <el-row><span class="buyer_font_1vw" > 收件地址：{{item.province + item.city + item.county + item.detailedAddress}}</span></el-row>
                </el-col>

                <el-col :span="6" class="" style="margin: 1vw 0vw 0vw 0vw">
                  <el-row class="">
                    <el-button type="danger" plain style="padding: 0.5vw 1vw 0.5vw 1vw;">编辑</el-button>
                    <el-button type="danger" plain style="padding: 0.5vw 1vw 0.5vw 1vw;">删除</el-button>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="14" :sm="13" :md="13" :lg="16" :xl="17"  style="min-height: 1px"></el-col>
            <el-col :xs="10" :sm="11" :md="11" :lg="8" :xl="7">
              <el-pagination
                  layout="prev, pager, next"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="page.pageNo"
                  :page-size="page.pageSize"
                  :total="page.total">
              </el-pagination>
            </el-col>
          </el-row>
        </div>

        <!--        新增加地址-->
        <div class=" group_address_center_buttom"  v-if="addAddress">

          <el-row style="margin: 0vw 0vw 1vw 0vw">
            <el-col :span="2"><span class="buyer_font_1vw">收件人：</span></el-col>
            <el-col :span="8"><el-input v-model="input" placeholder="请输入内容" style="width: 20vw"></el-input>
            </el-col>
            <el-col :span="2"><span class="buyer_font_1vw">收件人：</span></el-col>
            <el-col :span="8">
              <el-input v-model="input" placeholder="请输入内容" style="width: 20vw"></el-input>
            </el-col>
          </el-row>

          <el-row style="margin: 0vw 0vw 1vw 0vw">
            <el-col :span="2"><span class="buyer_font_1vw">收件人：</span></el-col>
            <el-col :span="8"><el-input v-model="input" placeholder="请输入内容" style="width: 20vw"></el-input>
            </el-col>
            <el-col :span="2"><span class="buyer_font_1vw">收件人：</span></el-col>
            <el-col :span="8">
              <el-input v-model="input" placeholder="请输入内容" style="width: 20vw"></el-input>
            </el-col>
          </el-row>

          <el-row style="margin: 0vw 0vw 1vw 0vw">
            <el-col :span="2"><span class="buyer_font_1vw">收件人：</span></el-col>
            <el-col :span="8"><el-input v-model="input" placeholder="请输入内容" style="width: 20vw"></el-input>
            </el-col>
            <el-col :span="2"><span class="buyer_font_1vw">收件人：</span></el-col>
            <el-col :span="8">
              <el-input v-model="input" placeholder="请输入内容" style="width: 20vw"></el-input>
            </el-col>
          </el-row>

          <el-row style="margin: 0vw 0vw 1vw 0vw">
            <el-col :span="2"><span class="buyer_font_1vw">收件人：</span></el-col>
            <el-col :span="8"><el-input v-model="input" placeholder="请输入内容" style="width: 20vw"></el-input>
            </el-col>
            <el-col :span="2"><span class="buyer_font_1vw">收件人：</span></el-col>
            <el-col :span="8">
              <el-input v-model="input" placeholder="请输入内容" style="width: 20vw"></el-input>
            </el-col>
          </el-row>

          <el-row style="margin: 0vw 0vw 1vw 0vw">
            <el-col :span="2"><span class="buyer_font_1vw">收件人：</span></el-col>
            <el-col :span="8"><el-input v-model="input" placeholder="请输入内容" style="width: 20vw"></el-input>
            </el-col>
            <el-col :span="2"><span class="buyer_font_1vw">收件人：</span></el-col>
            <el-col :span="8">
              <el-input v-model="input" placeholder="请输入内容" style="width: 20vw"></el-input>
            </el-col>
          </el-row>
          <el-row style="margin: 0vw 0vw 1vw 0vw">
<!--            <el-col :span="2"><span class="buyer_font_1vw">收件人：</span></el-col>
            <el-col :span="8"><el-input v-model="input" placeholder="请输入内容" style="width: 20vw"></el-input>
            </el-col>
            <el-col :span="2"><span class="buyer_font_1vw">收件人：</span></el-col>
            <el-col :span="8">
              <el-input v-model="input" placeholder="请输入内容" style="width: 20vw"></el-input>
            </el-col>-->
            <el-button type="danger" plain style="padding: 0.5vw 1vw 0.5vw 1vw;">保存</el-button>
            <el-button type="warning" plain style="padding: 0.5vw 1vw 0.5vw 1vw;">设为默认地址</el-button>
            <el-button type="info" plain style="padding: 0.5vw 1vw 0.5vw 1vw;">取消操作</el-button>
          </el-row>
        </div>

      </div>

    </div>

    <div style=""></div>
  </div>
</template>

<script>
import api from '@/api/buyer/buyerIndex.js'
import CryptoJS from "crypto-js";
export default {
  name: "buyerIndex",
  data() {
    return {
      accountDisplay: false,
      indexDisplay: true,
      input:'',
      editAccount:false,
      addressDisplay:false,
      currentPage: 1,
      pageSize: 12,
      totalCount: 50,
      addAddress:false,
        company: {},    //企业信息
        user: {},   //用户信息
        order: {},  //订单数
        workOrder: 0,   //工单数
        renewalNum: 0,  //待续费
        billingNum: 0,  //开票
        focusNum: 0, //关注
        noticeList: [], //公告信息
        addressList: [],
        page: {
            pageNo: 1,
            pageSize: 10,
            total: 0
        },
        userForm: {
          name: '',
            email: '',
            mobile: '',
            company: '',
            address: '',
            userId: '',
            userExtendsId: '',
        }
    }
  },
  mounted() {
    /*this.accountDisplay= false;
    this.indexDisplay= true;
    this.editAccount=false;
    this.addressDisplay=false;
    this.addAddress=false*/
    document.body.style.overflow="hidden"
      this.companyInfo();
      this.userInfo();
      this.orderInfo();
      this.workOrderNum();
      this.resourceRenewalNum();
      this.toBillingNum();
      this.toFocusNum();
      this.listNotice();
      this.listAddress();
  },
  /*  destroyed() {
      this.websocketOnClose();
    },*/
    filters: {
        filterTel(val) {
            if(val) {
                return (val.substring(3, 0)) + '****' + (val.substring(7));
            }
        }
    },
  methods: {
        showAll() {
            this.$router.push('/buyer/cloudOrders');
        },
        logOut() {
            this.$confirm('是否确认退出登录？', '确认').then(() => {
                this.$store.commit('logout');
                // 清除cookie
                this.setCookie('', '', -1);
                this.$router.push({
                    path: '/login'
                });
            })
        },
      setCookie(portId, psw, exdays) {
          // Encrypt，加密账号密码
          var cipherPortId = CryptoJS.AES.encrypt(
               portId + '',
               'secretkey123'
          ).toString()
          var cipherPsw = CryptoJS.AES.encrypt(psw + '', 'secretkey123').toString()
          console.log(cipherPortId + '/' + cipherPsw)// 打印一下看看有没有加密成功

          var exdate = new Date() // 获取时间
          exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays) // 保存的天数
          // 字符串拼接cookie，为什么这里用了==，因为加密后的字符串也有个=号，影响下面getcookie的字符串切割，你也可以使用更炫酷的符号。
          window.document.cookie =
               'currentPortId' +
               '==' +
               cipherPortId +
               ';path=/;expires=' +
               exdate.toGMTString()
          window.document.cookie =
               'password' +
               '==' +
               cipherPsw +
               ';path=/;expires=' +
               exdate.toGMTString()
      },
        saveUserInfo() {
            if(this.validateUserInfo()) {
                let form = this.userForm;
                api.updateUserInfo({
                    name: form.name,
                    email: form.email,
                    mobile: form.mobile,
                    phone: form.mobile,
                    userAddress: form.address,
                    userId: form.userId,
                    userExtendsId: form.userExtendsId
                }).then(res => {
                    if(res.code != 200) {
                        this.buyerMsg(res.message, 'error');
                        return;
                    }
                    this.buyerMsg('保存成功');
                    this.editAccount = false;
                    this.userInfo();
                })
            }
        },
      validateUserInfo() {
          let ifPass = true;
          for (let key in this.userForm) {
              if(!this.userForm[key]) {
                  ifPass = false;
              }
          }
          if(!ifPass) {
              this.buyerMsg('请输入完整的用户信息', 'warning');
              return false;
          }
          return true;
      },
      //企业信息
      companyInfo() {
        api.getCompanyInfo({}).then(res => {
            if(res.data) {
                this.company = res.data;
            }
        })
      },
      //用户信息
      userInfo() {
        api.getUserInfo({}).then(res => {
            if(res.data) {
                this.user = res.data;
            }
        })
      },
      //订单数量信息
      orderInfo() {
          api.statisticsOrder({}).then(res => {
              if(res.data) {
                  this.order = res.data;
              }
          })
      },
      //工单数
      workOrderNum() {
          api.statisticOrderWork({}).then(res => {
              if(res.data) {
                  this.workOrder = res.data;
              }
          })
      },
      //待续费资源
        resourceRenewalNum() {
          api.resourceRenewal({}).then(res => {
              if(res.data && res.data.length) {
                  let data = res.data[0];
                  this.renewalNum = data.resourcesNum;
              }

          })
        },
      //待开票数量
      toBillingNum() {
         api.toBiling({}).then(res => {
             if(res.data) {
                 this.billingNum = res.data;
             }
         })
      },
      //待关注数量
      toFocusNum() {
        api.toFocus({}).then(res => {
            if(res.data) {
                this.focusNum = res.data;
            }
        })
      },
      //系统公告信息
      listNotice() {
          api.noticeLists({
              pageNo: 1,
              pageSize: 10,
              type: 2
          }).then(res => {
              if(res.data) {
                  this.noticeList = res.data.records;
              }
          })
      },
      //地址列表
      listAddress() {
          api.addressLists({
              pageNo: this.page.pageNo,
              pageSize: this.page.pageSize
          }).then(res => {
              if(res.data) {
                  this.addressList = res.data.records;
                  this.page.total = res.data.total;
              }
          })
      },

    reloadFeix(){
      this.accountDisplay= false;
      this.indexDisplay= true;
      this.editAccount=false;
      this.addressDisplay=false;
      this.addAddress=false
    },
    goAccount(){
      this.reloadFeix();
      this.indexDisplay = false;
      this.accountDisplay = true;
      this.addressDisplay= false;

    },
    editAccountMe(){
          let user = JSON.parse(JSON.stringify(this.user));
          let company = JSON.parse(JSON.stringify(this.company));
          this.userForm.userId = user.userId;
          this.userForm.userExtendsId = user.userExtendsId;
          this.userForm.name = user.name;
          this.userForm.email = user.email;
          this.userForm.mobile = user.phone;
          this.userForm.company = company.name;
          this.userForm.address = user.userAddress;
      this.editAccount = true
    },
    closeEdit(){
      this.editAccount = false
    },
    goAddressDisplay(){
      // this.indexDisplay = false;
      // this.accountDisplay = false;
      // this.addressDisplay= true;
        this.$router.push('/buyer/address');
    },
    addAddressMe(){
      this.addAddress = true
    },
    // 每页显示的条数
    handleSizeChange(val) {
        this.page.pageSize = val;
        this.listAddress();
    },
    // 显示第几页
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.listAddress();

    },
  },
  created() {

  },

}
</script>
<style>
/*.my-order-container {*/
/*  width: 100%;*/
/*  height: 354px;*/
/*  background: red;*/
/*}*/

</style>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
@import '@/views/buyer/home/BuyerIndex.scss';
.cursor-item {
    cursor: pointer;
}
.product_scroll::-webkit-scrollbar{
  display: none;
}
.product_scroll{
  height: 30vw;
  overflow-y: auto;
}
.border_line{
  border-bottom: 1px solid #D6D6D6;
}
/deep/ .el-pager li.active{
  color: rgb(247, 81, 69);

  cursor: default;
}
//@media screen and (max-width: 767px) {
//
//}
//
///* 4. 大桌面显示器（大于等于768px）布局容器宽度为768px */
//@media screen and (min-width: 768px) {
//}


</style>
