import {get,post} from "@/utils/axios"

//企业信息
const getCompanyInfo = p => get("/api-seller/apiconsole/company/getCompanyInfo", p);

//用户信息
const getUserInfo = p => get("/api-seller/apiconsole/user/getUserInfo", p);

//订单数信息
const statisticsOrder = p => post("/api-seller/apiConsoleGoods/statisticalStatus", p);

//待完结工单数
const statisticOrderWork = p => post("/api-seller/apiconsole/workOrder/statisticalWorkOrder", p);

//待续费资源
const resourceRenewal = p => post("/api-seller/apiConsoleGoods/resourcesExpiration", p);

//待开票数量
const toBiling = p => post("/api-seller/apiconsole/invoice/toBeInvoiced", p);

//待关注
const toFocus = p => post("/api-seller/follow/followCount", p);

//系统公告
const noticeLists = p => get("/api-common/noticePortal/selectNoticeLists", p);

//地址列表
const addressLists = p => get("/api-seller/address/getAddressList", p);

//修改用户信息
const updateUserInfo = p => post("/api-seller/apiconsole/user/syncUserInfo", p);

export default {
    getCompanyInfo,
    getUserInfo,
    statisticsOrder,
    statisticOrderWork,
    resourceRenewal,
    toBiling,
    toFocus,
    noticeLists,
    addressLists,
    updateUserInfo,
}